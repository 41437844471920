import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _324a2b7a = () => interopDefault(import('../src/pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _3c18009b = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _abc0b948 = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _43240082 = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _4c85c580 = () => interopDefault(import('../src/pages/receipt.vue' /* webpackChunkName: "pages/receipt" */))
const _1d0c3c60 = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _f275fe42 = () => interopDefault(import('../src/pages/search-booking/index.vue' /* webpackChunkName: "pages/search-booking/index" */))
const _16a60adf = () => interopDefault(import('../src/pages/select-extras/index.vue' /* webpackChunkName: "pages/select-extras/index" */))
const _223728c8 = () => interopDefault(import('../src/pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _085a5f04 = () => interopDefault(import('../src/pages/travel-extras.vue' /* webpackChunkName: "pages/travel-extras" */))
const _42b37ad0 = () => interopDefault(import('../src/pages/unsupported-browser.vue' /* webpackChunkName: "pages/unsupported-browser" */))
const _2318afe3 = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _8fbe48c0 = () => interopDefault(import('../src/pages/manage-booking/modify.vue' /* webpackChunkName: "pages/manage-booking/modify" */))
const _08db5542 = () => interopDefault(import('../src/pages/manage-booking/receipt.vue' /* webpackChunkName: "pages/manage-booking/receipt" */))
const _358cefb8 = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _515574ff = () => interopDefault(import('../src/pages/select-extras/fasttrack/index.vue' /* webpackChunkName: "pages/select-extras/fasttrack/index" */))
const _3707c1c9 = () => interopDefault(import('../src/pages/select-extras/lounges/index.vue' /* webpackChunkName: "pages/select-extras/lounges/index" */))
const _7d7b8e82 = () => interopDefault(import('../src/pages/select-extras/product/index.vue' /* webpackChunkName: "pages/select-extras/product/index" */))
const _27c35c09 = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _7fa15a4c = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cookie-policy",
    component: _324a2b7a,
    name: "cookie-policy"
  }, {
    path: "/maintenance",
    component: _3c18009b,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _abc0b948,
    name: "manage-booking"
  }, {
    path: "/privacy-policy",
    component: _43240082,
    name: "privacy-policy"
  }, {
    path: "/receipt",
    component: _4c85c580,
    name: "receipt"
  }, {
    path: "/search",
    component: _1d0c3c60,
    name: "search"
  }, {
    path: "/search-booking",
    component: _f275fe42,
    name: "search-booking"
  }, {
    path: "/select-extras",
    component: _16a60adf,
    name: "select-extras"
  }, {
    path: "/terms-and-conditions",
    component: _223728c8,
    name: "terms-and-conditions"
  }, {
    path: "/travel-extras",
    component: _085a5f04,
    name: "travel-extras"
  }, {
    path: "/unsupported-browser",
    component: _42b37ad0,
    name: "unsupported-browser"
  }, {
    path: "/manage-booking/cancel",
    component: _2318afe3,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/modify",
    component: _8fbe48c0,
    name: "manage-booking-modify"
  }, {
    path: "/manage-booking/receipt",
    component: _08db5542,
    name: "manage-booking-receipt"
  }, {
    path: "/manage-booking/update-details",
    component: _358cefb8,
    name: "manage-booking-update-details"
  }, {
    path: "/select-extras/fasttrack",
    component: _515574ff,
    name: "select-extras-fasttrack"
  }, {
    path: "/select-extras/lounges",
    component: _3707c1c9,
    name: "select-extras-lounges"
  }, {
    path: "/select-extras/product",
    component: _7d7b8e82,
    name: "select-extras-product"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _27c35c09,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _7fa15a4c,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
